import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebaseApp";

import Content from "../Content";

export default function AskForm() {
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      // firestore로 데이터 생성
      await addDoc(collection(db, "asks"), {
        title: title,

        email: email,

        content: content,

        createdAt: new Date().toLocaleDateString("ko", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
      });

      toast.success("게시글을 생성했습니다.");
      navigate("/");
    } catch (e) {
      console.error("Error adding document: ", e);
      toast.error("게시글 생성 중 오류가 발생했습니다.");
    }
  };

  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;

    if (name === "title") {
      setTitle(value);
    }

    if (name === "email") {
      setEmail(value);
    }

    if (name === "content") {
      setContent(value);
    }
  };

  return (
    <div className="form-container dark-mode" style={{ paddingBottom: "50px" }}>
      <div className="text-group">
        <div className="form form-title">건의함</div>
        <div className="form form-text">
          <Content description="안녕하세요. 테스트럽을 이용해주셔서 감사합니다./n보다 나은 경험을 제공하기 위해 여러분들의 의견에 귀 기울이고 있습니다./n 좋은 점, 아쉬운 점들을 편하게 말씀해주세요." />
        </div>
      </div>
      <form className="form " onSubmit={onSubmit}>
        <div className="form__block">
          <label htmlFor="content"></label>
          <input
            type="text"
            name="title"
            id="title"
            required
            onChange={onChange}
            value={title}
            placeholder="제목" // placeholder 속성 추가
            className="request-input"
          />
        </div>

        <div className="form__block">
          <label htmlFor="content"></label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="이메일" // placeholder 속성 추가
            required // Enforce that this field is filled
            onChange={onChange}
            value={email}
            className="request-input"
          />
        </div>

        <div className="form__block">
          <label htmlFor="content"></label>
          <textarea
            name="content"
            id="content"
            placeholder="내용을 적어주세요" // placeholder 속성 추가
            required // Enforce that this field is filled
            onChange={onChange}
            value={content}
            className="request-input"
          />
        </div>

        <div className="form__block">
          <input type="submit" className="request__btn--submit" value="제출" />
        </div>
      </form>
    </div>
  );
}
