import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../context/ThemeContext";
import styled from "styled-components";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);

  const navigate = useNavigate();
  const dimmedRef = useRef(null);
  const searchInputRef = useRef(null);
  const context = useContext(ThemeContext);
  const handleMenuClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    navigate(`/search?q=${e.target.value}`);
  };

  const toggleSearch = () => {
    setSearchActive(!searchActive);
    if (!searchActive && searchInputRef.current) {
      // If search is being activated, focus on the input and expand width
      // Clear the search input when toggling
      setSearchValue("");
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 1000);
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleDimmedClick = (e) => {
    if (
      (dimmedRef.current && dimmedRef.current.contains(e.target)) ||
      e.target.classList.contains("header") // Check if the click occurred outside the mobile navbar
    ) {
      // Check if the click occurred outside the modal content or mobile navbar
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDimmedClick);

    return () => {
      document.removeEventListener("click", handleDimmedClick);
    };
  });

  return (
    <div className={`header ${isOpen ? "dimmed" : ""}`}>
      <div className="logo-container">
        <button type="button" onClick={() => navigate("/")}>
          {context.theme === "light" ? (
            <img
              src="https://res.cloudinary.com/depcihzgg/image/upload/v1707348948/%EC%A0%9C%EB%AA%A9_%EC%97%86%EC%9D%8C_300_x_300_px_200_x_100_px_5_fyihpa.png"
              alt="logo-black"
              className="header-logo"
              style={{ width: "100px" }}
            />
          ) : (
            <img
              src="https://res.cloudinary.com/depcihzgg/image/upload/v1707348762/%EC%A0%9C%EB%AA%A9_%EC%97%86%EC%9D%8C_300_x_300_px_200_x_100_px_4_qashel.png"
              alt="logo-white"
              className="header-logo"
              style={{ width: "100px" }}
            />
          )}
        </button>

        <div className={`header-side ${searchActive ? "search-active" : ""}`}>
          {searchActive ? (
            <div className="search-container">
              <Input
                value={searchValue}
                onChange={handleChange}
                className="nav__input"
                type="text"
                placeholder="검색해주세요."
                ref={searchInputRef}
              />
            </div>
          ) : context.theme === "light" ? (
            <img
              src="https://res.cloudinary.com/depcihzgg/image/upload/v1706005654/14_cyjzli.png"
              alt="search-black"
              onClick={toggleSearch}
              className="search-icon"
            />
          ) : (
            <img
              src="https://res.cloudinary.com/depcihzgg/image/upload/v1706005755/15_rtcmit.png"
              alt="search-white"
              onClick={toggleSearch}
              className="search-icon"
            />
          )}

          <div
            role="presentation"
            className="navbar__button"
            onClick={handleMenuClick}
          >
            {context.theme === "light" ? (
              <img
                src="https://res.cloudinary.com/depcihzgg/image/upload/v1706005636/12_svf1rq.png"
                alt="hamburger_black"
                className="search-icon"
                style={{ marginTop: "4px" }}
              />
            ) : (
              <img
                src="https://res.cloudinary.com/depcihzgg/image/upload/v1706005785/13_tr53ge.png"
                alt="hamburger_white"
                className="search-icon"
                style={{ marginTop: "4px" }}
              />
            )}
          </div>
        </div>
      </div>

      {/* mobile navbar */}
      {isOpen && (
        <div className="navbar--mobile" ref={dimmedRef}>
          <div className="sub-menu">
            <button
              type="button"
              className="navbar__list--button"
              onClick={() => {
                navigate("/request");
                closeMenu();
              }}
            >
              <div className="post-flex">
                {context.theme === "light" ? (
                  <img
                    src="https://res.cloudinary.com/depcihzgg/image/upload/v1706767824/20_othbgh.png"
                    alt="postbox-light"
                  />
                ) : (
                  <img
                    src="https://res.cloudinary.com/depcihzgg/image/upload/v1707115524/21_uwqofj.png"
                    alt="postbox-dark"
                  />
                )}
                <span className="navbar__list--item--mobile">제휴문의</span>
              </div>
            </button>
            <button
              type="button"
              className="navbar__list--button"
              onClick={() => {
                navigate("/post");
                closeMenu();
              }}
            >
              <div className="post-flex">
                {context.theme === "light" ? (
                  <img
                    src="https://res.cloudinary.com/depcihzgg/image/upload/v1706767824/21_fe0afh.png"
                    alt="postbox-light"
                  />
                ) : (
                  <img
                    src="https://res.cloudinary.com/depcihzgg/image/upload/v1707115524/23_jslue8.png"
                    alt="postbox-dark"
                  />
                )}

                <span className="navbar__list--item--mobile">건의함</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const Input = styled.input`
  border-radius: 5px;
  color: white;
  padding: 5px;
  border: none;
  box-sizing: border-box;
  outline: none;
  background-color: #fff;
  color: #777;
  transition: width 0.4s ease-in-out;
  font-size: 0.75rem;
  max-width: 12cm; /* Set the maximum width to 20cm */
  width: 100%; /* Use 100% width so it takes the available space */
`;
