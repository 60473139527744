import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import AskForm from "./postform/AskForm";
const HomePage = lazy(() => import("../pages/home"));
const TestPage = lazy(() => import("../pages/test"));
const AddList = lazy(() => import("../pages/test/addList"));
const AboutPage = lazy(() => import("../pages/about/About"));
const QuantityPage = lazy(() => import("../pages/hometest/Quantity"));
const RequestPage = lazy(() => import("../pages/request"));
const PostPage = lazy(() => import("../pages/hometest"));
const SearchPage = lazy(() => import("../pages/search/SearchPage"));
const AskPage = lazy(() => import("../pages/ask"));

const ResultPage = lazy(() => import("../pages/test/ResultPage"));
const Router = () => {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="/test/:id" element={<TestPage />} />

        <Route path="/post" element={<AskPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/request" element={<RequestPage />} />
        <Route path="/quantity" element={<QuantityPage />} />
        <Route path="/search/" element={<SearchPage />} />

        <Route path="/test/:id/result/:number" element={<ResultPage />} />
        <Route path="/test/category/:category" element={<PostPage />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
