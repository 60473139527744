import { useNavigate } from "react-router-dom";

import { useContext } from "react";
import ThemeContext from "../context/ThemeContext";

export default function Footer() {
  const navigate = useNavigate();
  const context = useContext(ThemeContext);
  const imageSource =
    context.theme === "light"
      ? "https://res.cloudinary.com/depcihzgg/image/upload/v1705669882/3_mk8v91.png"
      : "https://res.cloudinary.com/depcihzgg/image/upload/v1705669882/4_amu1fi.png";

  return (
    <div className="footer">
      <div className="footer__grid">
        <img
          src={imageSource}
          onClick={() => navigate("/")}
          className="footer__theme-btn"
        />

        <>
          {context.theme === "light" ? (
            <img
              src="https://res.cloudinary.com/depcihzgg/image/upload/v1705669882/1_vhmpqu.png"
              onClick={context.toggleMode}
              className="footer__theme-btn"
            />
          ) : (
            <img
              src="https://res.cloudinary.com/depcihzgg/image/upload/v1705669884/2_q0b3n8.png"
              onClick={context.toggleMode}
              className="footer__theme-btn"
            />
          )}
        </>
      </div>
    </div>
  );
}
