import React from "react";

function Content({ description }) {
  // description 문자열 내의 /n을 \n으로 변환
  const formattedDescription = description.replace(/\/n/g, "\n");

  return (
    <div>
      {formattedDescription.split("\n").map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  );
}
export default Content;
