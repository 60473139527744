import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeContextProvider } from "./context/ThemeContext";
import { HelmetProvider } from "react-helmet-async";

const client = new QueryClient({
  defaultOptions: {},
});

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <ThemeContextProvider>
      <BrowserRouter>
        <QueryClientProvider client={client}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeContextProvider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <ThemeContextProvider>
      <BrowserRouter>
        <QueryClientProvider client={client}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeContextProvider>,
    rootElement
  );
}
