import "./App.css";
import Router from "./components/Router";
import { Layout } from "./components/Layout";
import useLoadKakao from "./components/share/hooks/useLoadKakao";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import ThemeContext from "./context/ThemeContext";

import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";

function App() {
  useLoadKakao();

  const context = useContext(ThemeContext);
  return (
    <div className={context.theme === "light" ? "white" : "dark"}>
      <Layout>
        <ToastContainer
          theme="dark"
          autoClose={1000}
          hideProgressBar
          newestOnTop
        />
        <Helmet>
          <title>테스트럽</title>
          <meta
            name="description"
            content="'너만 몰라 내가 좋아하는거'
달달한 연애테스트부터  다양한 MBTI, 관상, 상식 테스트를 같이 즐겨보자"
          />
          <meta property="og:title" content="테스트럽" />
          <meta
            property="og:description"
            content="놓치면 후회할 재미있는 mbti, 연애, 관상 테스트 모음"
          />
          <meta
            property="og:image"
            content="https://res.cloudinary.com/depcihzgg/image/upload/v1706604276/%EB%B0%B0%EB%84%88_5_e3eirn.png"
          />
          <meta property="og:url" content="http://testluv.com/" />
          <meta property="twitter:title" content="테스트럽" />
          <meta
            property="twitter:description"
            content="놓치면 후회할 재미있는 mbti, 연애, 관상 테스트 모음"
          />
          <meta
            property="twitter:image"
            content="https://res.cloudinary.com/depcihzgg/image/upload/v1706604276/%EB%B0%B0%EB%84%88_5_e3eirn.png"
          />
          <meta property="twitter:url" content="http://testluv.com/" />
        </Helmet>
        <Router />
      </Layout>
    </div>
  );
}

export default App;
